import { useContext } from "react";
import { ItcState } from "apinet/models";
import { eventBus } from "core/events/eventBus";
import { AppNotification, AppNotificationLevel, BaseEvent } from "common/eventBusTypes";
import desktopManager from "core/desktop/desktopManager";
import { ItcDetailsWindow } from "domain/itc/details/ItcDetailsWindow";
import { ToastManagerContext } from "core/content/toast/ToastManagerContext";
import { getCurrentLangCode } from "core/intl";
import { dateToUnix } from "core/utils";

const itcStateChangedAudio = {
  [ItcState.noLiveData]: "",
  [ItcState.ok]: "teaspoon.mp3",
  [ItcState.turnOff]: "teaspoon.mp3",
  [ItcState.disconnected]: "teaspoon.mp3",
  [ItcState.criticalError]: "blad_krytyczny.mp3",
  [ItcState.error]: "blad.mp3",
  [ItcState.manualControl]: "sterowanie_reczne.mp3",
  [ItcState.noAsr]: "blad_komunikacji_ze_sterownikiem.mp3",
  [ItcState.noModem]: "blad_komunikacji_z_modemem.mp3",
  [ItcState.noPower]: "brak_zasilania.mp3",
};

export function AppNotificationToToastListener() {
  const toastManager = useContext(ToastManagerContext);

  eventBus.useEvent<AppNotification>("appNotification", ev => {
    toastManager.add({
      timestamp: ev.occuredAt ? dateToUnix(new Date(ev.occuredAt)) : undefined,
      kind: ev.level,
      title: ev.header,
      message: (ev.content || "").replace(/<br\/?>/gi, "\n"),
      customIconClasses: getCustomIconClasses(ev),
      playNotificationSound:
        (ev.itcState && getCurrentLangCode() === "pl" && itcStateChangedAudio[ev.itcState]) || true,
      action: ev.itcId
        ? () =>
            desktopManager.openWindow({
              component: ItcDetailsWindow,
              props: {
                itcId: ev.itcId!,
              },
            })
        : undefined,
    });
  });

  eventBus.useEvent<TestNotificationGenerated>("testNotificationGenerated", ev => {
    toastManager.add({
      timestamp: ev.occuredAt ? dateToUnix(new Date(ev.occuredAt)) : undefined,
      kind: ev.level,
      message: ev.message,
      title: ev.title,
    });
  });

  return null;
}

function getCustomIconClasses(ev: AppNotification): string | undefined {
  if (ev.kind === "itcServiceRequestOperation") {
    return "fas fa-wrench text-gradient-mode bg-gradient-info";
  }

  return undefined;
}

export interface TestNotificationGenerated extends BaseEvent {
  code: "testNotificationGenerated";
  level: AppNotificationLevel;
  message: string;
  title: string;
}
